<template>
  <b-card>
    <error-alert
      :errors="errors"
      message="Виникла помилка при пошуку моніторингу карток!"
    />
    <ValidationObserver v-slot="{ handleSubmit }" ref="formFilterValidation">
      <b-form @submit.prevent="handleSubmit(getCardsMonitoring)">
        <b-form-row>
          <b-col md="6" lg="4" xl="3">
            <ValidationProvider
              name="період"
              v-slot="{ errors, dirty, validated, valid, changed }"
              vid="date_gte"
            >
              <b-form-group label="Зазначте період">
                <date-range-picker
                  :start-date="filter.processedAtFrom.value"
                  :end-date="filter.processedAtTo.value"
                  v-on:start-date-change="changeDateFrom"
                  v-on:end-date-change="changeDateTo"
                  :state="
                    setValidationState(errors, dirty, validated, valid, changed)
                  "
                  :time-picker="false"
                  :format-date="'dd.mm.yyyy'"
                  style="width: 100%"
                />
                <b-form-invalid-feedback
                  :state="errors.length === 0"
                  v-for="(error, index) in errors"
                  v-bind:key="index"
                >
                  {{ error }}
                </b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col md="6" lg="3">
            <ValidationProvider
              name="Тип платежу"
              :rules="{ required: true }"
              v-slot="{ errors, dirty, validated, valid, changed }"
              vid="is_payer_where"
            >
              <b-form-group label="Тип платежу">
                <b-form-select
                  text-field="name"
                  value-field="value"
                  v-model="filter.isPayer.value"
                  :options="operationTypesList"
                  :state="
                    setValidationState(errors, dirty, validated, valid, changed)
                  "
                />
                <b-form-invalid-feedback
                  :state="errors.length === 0"
                  v-for="(error, index) in errors"
                  v-bind:key="index"
                >
                  {{ error }}
                </b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col md="6" lg="2" xl="1">
            <ValidationProvider
              name="валюта"
              v-slot="{ errors, dirty, validated, valid, changed }"
              vid="currency_where"
            >
              <b-form-group label="Валюта">
                <b-form-select
                  v-model="filter.currency.value"
                  :options="$stringConstants('CURRENCY_LIST')"
                  :state="
                    setValidationState(errors, dirty, validated, valid, changed)
                  "
                >
                  <template #first>
                    <b-form-select-option value=""></b-form-select-option>
                  </template>
                </b-form-select>
                <b-form-invalid-feedback
                  :state="errors.length === 0"
                  v-for="(error, index) in errors"
                  v-bind:key="index"
                >
                  {{ error }}
                </b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col md="6" lg="3">
            <ValidationProvider
              name="Сума операцій"
              :rules="{ regex: /(^\d{1,8}([.]\d{1,2})?)$/ }"
              v-slot="{ errors, dirty, validated, valid, changed }"
              vid="uah_amount_having_sum_gte"
            >
              <b-form-group label="Сума операцій дорівнює/перевищує в грн">
                <b-form-input
                  type="number"
                  min="0"
                  step="0.01"
                  v-model="filter.amountHaving.value"
                  trim
                  :state="
                    setValidationState(errors, dirty, validated, valid, changed)
                  "
                />
                <b-form-invalid-feedback
                  :state="errors.length === 0"
                  v-for="(error, index) in errors"
                  v-bind:key="index"
                >
                  {{ error }}
                </b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col md="6" lg="3">
            <ValidationProvider
              name="Профіль СПФМ"
              v-slot="{ errors, dirty, validated, valid, changed }"
              vid="financial_model_id_where"
              rules="required"
            >
              <b-form-group label="Профіль СПФМ">
                <b-form-select
                  text-field="name"
                  value-field="id"
                  v-model="filter.financialModelId.value"
                  :options="meFinancialModels"
                  :state="
                    setValidationState(errors, dirty, validated, valid, changed)
                  "
                />
                <b-form-invalid-feedback
                  :state="errors.length === 0"
                  v-for="(error, index) in errors"
                  v-bind:key="index"
                >
                  {{ error }}
                </b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col>
            <ValidationProvider
              name="призначення платежу"
              v-slot="{ errors, dirty, validated, valid, changed }"
              vid="destination_code_id_where_in0"
            >
              <b-form-group label="Призначення платежу">
                <multiselect
                  v-model="filter.operationDestinationCodes.value"
                  :options="[
                    {
                      all: 'Всі',
                      options: operationDestinationCodeList.map((e) => e.id),
                    },
                  ]"
                  :multiple="true"
                  :state="
                    setValidationState(errors, dirty, validated, valid, changed)
                  "
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  group-values="options"
                  group-label="all"
                  :group-select="true"
                  :show-labels="false"
                  placeholder=""
                  :custom-label="customDestinationCodeLabel"
                >
                  <template class="checkbox-label" v-slot:option="scope">
                    <img
                      v-if="
                        !scope.option.hasOwnProperty('$groupLabel') &&
                        destinationCodeHasEndedAtDate(scope.option)
                      "
                      src="/images/text-help.svg"
                      alt="help"
                      style="margin-right: 10px"
                      v-b-tooltip
                      :title="tooltipDestinationCode(scope.option)"
                    />
                    <input
                      v-if="!scope.option.hasOwnProperty('$groupLabel')"
                      type="checkbox"
                      :checked="
                        filter.operationDestinationCodes.value.includes(
                          scope.option
                        )
                      "
                      @focus.prevent
                    />
                    <template v-if="scope.option.hasOwnProperty('$groupLabel')">
                      {{ scope.option.$groupLabel }}
                    </template>
                    <template v-else>
                      {{ customDestinationCodeLabel(scope.option) }}
                    </template>
                  </template>
                </multiselect>
                <b-form-invalid-feedback
                  :state="errors.length === 0"
                  v-for="(error, index) in errors"
                  v-bind:key="index"
                >
                  {{ error }}
                </b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-form-row>

        <b-form-row>
          <b-col>
            <b-button
              variant="primary"
              @click="getCardsMonitoring()"
              class="mb-2"
              :disabled="submitting"
            >
              <i class="fa fa-search"></i> Пошук
            </b-button>
          </b-col>
          <b-col class="text-right justify-content-end">
            <b-button
              variant="primary"
              @click="exportData"
              :disabled="submittingExport || meta.total === 0"
              v-if="$auth.can($stringConstants('PERMISSION_EXPORT_MONITORING'))"
              class="mb-2"
            >
              <i class="fa fa-dot-circle-o"></i> Вивантажити xlsx
            </b-button>
          </b-col>
        </b-form-row>
      </b-form>
    </ValidationObserver>

    <b-row class="pb-3">
      <b-col>
        <b-list-group horizontal="md" class="text-center justify-content-end">
          <b-list-group-item
            class="text-center border-0"
            :class="meta.total > 0 ? 'bg-primary text-white' : 'bg-secondary'"
            :style="meta.total > 0 ? '' : 'opacity: 0.65'"
          >
            <b-button
              :variant="meta.total > 0 ? 'primary' : 'secondary'"
              :disabled="!meta.total > 0 || isSubmittingCalculate"
              @click="getTotalSum"
              class="rounded-0 h-100 info-amount-block"
            >
              <div v-if="!isSubmittingCalculate">
                <b-icon-wallet2 scale="0.75" />
                Порахувати загальну суму
              </div>
              <b-spinner small type="grow" v-else>Завантаження</b-spinner>
            </b-button>
          </b-list-group-item>
          <b-list-group-item
            class="text-center border-0"
            :class="meta.total > 0 ? 'bg-primary text-white' : 'bg-secondary'"
            :style="meta.total > 0 ? '' : 'opacity: 0.65'"
          >
            <div class="d-flex justify-content-center align-items-center h-100">
              Кількість карток:
              <b-badge variant="light" class="ml-1" v-if="total.count">{{
                total.count
              }}</b-badge>
            </div>
          </b-list-group-item>

          <b-list-group-item
            class="text-center border-0"
            :class="meta.total > 0 ? 'bg-primary text-white' : 'bg-secondary'"
            :style="meta.total > 0 ? '' : 'opacity: 0.65'"
          >
            <div class="d-flex justify-content-center align-items-center h-100">
              Загальна сума операцій:
              <b-badge variant="light" class="ml-1">{{
                total.sum | amount
              }}</b-badge>
            </div>
          </b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>

    <b-table
      show-empty
      :responsive="true"
      :hover="true"
      :bordered="true"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :items="items"
      :fields="fields"
      :busy="submitting"
    >
      <template #table-busy>
        <div class="text-center text-dark my-2">
          <b-spinner class="align-middle mr-1"></b-spinner>
          <strong>Завантаження...</strong>
        </div>
      </template>
      <template v-slot:cell(hash)="data">
        <b-link
          target="_blank"
          :to="{
            name: 'operations',
            query: createQuery(data.item, 'hash'),
          }"
        >
          {{ data.value }}
        </b-link>
      </template>

      <template v-slot:cell(pan)="data">
        <b-link
          target="_blank"
          :to="{
            name: 'operations',
            query: createQuery(data.item, 'pan'),
          }"
        >
          {{ data.value }}
        </b-link>
      </template>

      <template v-slot:cell(sum)="data">
        {{ data.value | amount }}
      </template>

      <template v-slot:cell(avg_sum)="data">
        {{ data.value | amount }}
      </template>

      <template #empty="scope">
        <div class="h4 text-center">Дані не знайдено</div>
      </template>
    </b-table>

    <b-row>
      <b-col cols="auto" class="mr-auto p-3">
        <b-pagination
          v-model="meta.page"
          prev-text="Попередня"
          next-text="Наступна"
          hide-goto-end-buttons
          :per-page="meta.per_page"
          :total-rows="meta.total"
          @change="onChangePage"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import api from "@/api/api";
import moment from "moment/moment";
import DateRangePicker from "@/components/DateRangePickerWrapper";
import { mapGetters } from "vuex";
import mixins from "@/mixins";
import ErrorAlert from "@/components/ErrorAlert.vue";
import Multiselect from "vue-multiselect";
export default {
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.$auth.can(vm.$stringConstants("PERMISSION_VIEW_MONITORING")))
        next();
      else next({ name: "Page404" });
    });
  },
  name: "CardsMonitoring",
  components: { Multiselect, ErrorAlert, DateRangePicker },
  mixins: [mixins],
  data() {
    return {
      operationTypesList: [
        { name: "Вхідні платежі", value: 1 },
        { name: "Вихідні платежі", value: 0 },
      ],
      sortBy: "",
      sortDesc: false,
      isSubmittingCalculate: false,
      meta: {
        total: 0,
        page: 1,
        per_page: 20,
        sort_by: "",
      },
      filter: {},
      fields: [
        {
          key: "pan",
          label: "Номер картки",
        },
        {
          key: "hash",
          label: "Хеш",
        },
        {
          key: "number_of_operations",
          label: "Кількість операцій",
          sortable: false, //TODO in next task add sort
          tdClass: "text-right",
          thClass: "text-right",
        },
        {
          key: "sum",
          label: "Оборот UAH за період",
          sortable: false, //TODO in next task add sort
          tdClass: "text-right",
          thClass: "text-right",
        },
        {
          key: "avg_sum",
          label: "Середня сума",
          sortable: false, //TODO in next task add sort
          tdClass: "text-right",
          thClass: "text-right",
        },
      ],
      items: [],
      errors: {},
      submitting: false,
      submittingExport: false,
      total: {
        count: 0,
        sum: 0.0,
      },
    };
  },
  computed: {
    ...mapGetters({
      meFinancialModels: "auth/meFinancialModels",
      operationDestinationCodeList: "dictionary/allOperationDestinationCode",
    }),
    sort: function () {
      if (this.sortDesc) return "-" + this.sortBy;
      return this.sortBy;
    },
  },
  beforeMount() {
    this.filter = this.getDefaultFilter();
  },
  methods: {
    getDefaultFilter: function () {
      return {
        processedAtFrom: {
          name: "date",
          type: "gte",
          date: true,
          value: moment().subtract(1, "days").startOf("day").toDate(),
        },
        processedAtTo: {
          name: "date",
          type: "lte",
          date: true,
          value: moment().subtract(1, "days").endOf("day").toDate(),
        },
        isPayer: {
          name: "is_payer",
          type: "where",
          value: "",
        },
        financialModelId: {
          name: "financial_model_id",
          type: "where",
          value: "",
        },
        currency: {
          name: "currency",
          type: "where",
          value: "",
        },
        operationDestinationCodes: {
          name: "destination_code_id",
          type: "whereIn",
          value: [],
        },
        amountHaving: {
          name: "uah_amount",
          type: "havingSumGte",
          value: null,
        },
      };
    },
    customDestinationCodeLabel(id) {
      const code = this.operationDestinationCodeList.find((e) => e.id === id);

      return `(${code.value}) ${code.name}`;
    },
    tooltipDestinationCode(id) {
      return "Активний по " + this.destinationCodeHasEndedAtDate(id);
    },
    destinationCodeHasEndedAtDate(id) {
      const code = this.operationDestinationCodeList.find((e) => e.id === id);

      return code.ended_at;
    },
    changeDateFrom: function (v) {
      this.filter.processedAtFrom.value = v;
    },
    changeDateTo: function (v) {
      this.filter.processedAtTo.value = v;
    },
    onChangePage(page) {
      this.getCardsMonitoring(page);
    },
    prepareFilterParams: function () {
      const filters = {};
      Object.entries(this.filter).forEach(([, filter]) => {
        if (
          filter.value !== null &&
          filter.value !== "" &&
          filter.value.length !== 0
        ) {
          filters[`filter[${filter.name}@${filter.type}]`] = filter.date
            ? moment(filter.value).format("YYYY-MM-DD")
            : filter.value;
        }
      });
      return {
        page: this.meta.page,
        per_page: this.meta.per_page,
        ...filters,
      };
    },
    getCardsMonitoring(page = 1) {
      this.errors = {};
      this.$refs.formFilterValidation.validate().then((success) => {
        if (!success) {
          return;
        }

        this.submitting = true;
        this.items = [];
        this.meta.page = page;
        api
          .getCardsMonitoring(this.prepareFilterParams())
          .then(({ data }) => {
            this.items = data.data;
            this.meta.total = data.meta.total;
          })
          .catch(({ response }) => {
            this.$set(this, "errors", response.data);
            if (response.status === 400) {
              this.$refs.formFilterValidation.setErrors(
                this.errors.description
              );
            }
          })
          .finally(() => (this.submitting = false));
      });
    },
    exportData: function () {
      this.errors = {};
      this.$refs.formFilterValidation.validate().then((success) => {
        if (!success) {
          return;
        }

        this.submittingExport = true;
        api
          .exportCardsMonitoring(this.prepareFilterParams())
          .then(() => {
            this.$snotify.success("Файл буде надіслано на вашу пошту");
          })
          .catch(({ response }) => {
            this.$set(this, "errors", response.data);
            if (response.status === 400) {
              this.$refs.formFilterValidation.setErrors(
                this.errors.description
              );
            }
          })
          .finally(() => (this.submittingExport = false));
      });
    },
    getTotalSum() {
      this.errors = {};
      this.$refs.formFilterValidation.validate().then((success) => {
        if (!success) {
          return;
        }

        this.isSubmittingCalculate = true;
        this.total = {
          sum: 0.0,
          count: 0,
        };
        api
          .getTotalSum(this.prepareFilterParams())
          .then((response) => {
            this.total = {
              sum: response.data.sum,
              count: response.data.count,
            };
          })
          .catch(({ response }) => {
            this.$set(this, "errors", response.data);
            if (response.status === 400) {
              this.$refs.formFilterValidation.setErrors(
                this.errors.description
              );
            }
          })
          .finally(() => (this.isSubmittingCalculate = false));
      });
    },
    createQuery(item, $format) {
      const query = {};
      if (this.filter.processedAtFrom.value) {
        const processedAtFromDate = this.filter.processedAtFrom.value;
        processedAtFromDate.setHours(0, 0, 0);
        query.processedAtFrom = processedAtFromDate.toISOString();
      }

      if (this.filter.processedAtTo.value) {
        const processedAtToDate = this.filter.processedAtTo.value;
        processedAtToDate.setHours(23, 59, 59);
        query.processedAtTo = processedAtToDate.toISOString();
      }

      if (this.filter.financialModelId.value) {
        query.financialModelId = this.filter.financialModelId.value;
      }

      if (this.filter.operationDestinationCodes.value.length > 0) {
        query.operationDestinationCode =
          this.filter.operationDestinationCodes.value.join(",");
      }

      if (this.filter.currency.value) {
        query.currency = this.filter.currency.value;
      }

      if ($format === "pan" && item.pan) {
        if (item.isPayer) {
          query["payerCardMask"] = item.pan;
        } else {
          query["payeeCardMask"] = item.pan;
        }
      }

      if ($format === "hash" && item.hash) {
        if (item.isPayer) {
          query["payerCardHash"] = item.hash;
        } else {
          query["payeeCardHash"] = item.hash;
        }
      }

      return query;
    },
  },
  watch: {
    sort: function () {
      this.meta.sort_by = this.sort;
      this.getCardsMonitoring();
    },
  },
};
</script>
